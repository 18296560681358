<template>
  <b-card no-body>
    <loading :show="is_loading">
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="mt-1 p-2"
            @submit.prevent="handleSubmit(submitForm)"
        >
          <b-row>
            <!-- Field: Name -->
            <b-col
                cols="12"
            >
              <validation-provider
                  #default="{errors}"
                  name="name"
                  rules="required"
              >
                <b-form-group
                    :label="$i18n.t('Role Name')"
                    label-for="role"
              >
                <b-form-input
                    id="name"
                    v-model="name"
                    autofocus
                    :state="errors.length > 0 ? false:null"
                    trim
                    placeholder="Name"
                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Sales Permission -->
            <b-col
                cols="12"
            >
              <validation-provider
                  #default="{errors}"
                  name="sales_permission"
              >
                <b-form-group
                    :label="$i18n.t('sales_permission')"
                    label-for="role"
                >
                  <v-select
                      v-model="sales_permission"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sales_permission_options"
                      label="title"
                      :reduce="(val) => val.value"
                      class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
            >
              <validation-provider
                  #default="{errors}"
                  name="brand"
              >
                <b-form-group
                    :label="$i18n.t('brand')"
                    label-for="brand"
                >
                  <v-select
                      v-model="brand"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="brands"
                      class="w-100"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
            >
              <b-form-group class="mt-1">
                <!-- checkbox -->
                <b-form-checkbox
                    v-model="s4s_staff"
                    name="checkbox-1"
                >
                  {{ $i18n.t('s4s_staff') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        <!-- PERMISSION TABLE -->
        <validation-provider
            #default="{errors}"
            name="permissions"
            rules=""
        >
          <b-card
              no-body
              class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                    icon="LockIcon"
                    size="18"
                />
                <span class="align-middle ml-50">  {{ $i18n.t('Permission') }}</span>
              </b-card-title>
            </b-card-header>
            <b-table
                striped
                responsive
                class="mb-0"
                :items="permissions"
            >
              <template #cell(module)="data">
                {{ data.value }}
              </template>
              <template #cell()="data">
                <b-form-checkbox
v-model="permissions[data.index][data.field.key]"
:checked="data.value"
:disabled="data.item.module === main_module"
                                 @change="changeCheckboxPermission($event, data)"
/>
              </template>
            </b-table>
          </b-card>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <b-row class="mt-2">
          <b-col>
            <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
            >
              {{ $i18n.t('Update') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </validation-observer>
    </loading>
  </b-card>
</template>

<script>
import {alphaNum, email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      required,
      alphaNum,
      email,
      permissions: [],
      keys: ['create', 'read', 'update', 'delete'],
      name: '',
      brand: '',
      main_module: '',
      is_loading: false,
      sales_permission: null,
      sales_permission_options: [],
      brands: [],
      s4s_staff: false,
    }
  },
  async created() {
    this.is_loading = true
    await this.getUtils()
    await this.getData()
  },
  methods: {
    getUtils() {
      this.$http.get('utils/roles')
          .then(res => {
            this.main_module = res.data.data.main_module
            this.sales_permission_options = res.data.data.sales_permission_options
            this.brands = res.data.data.brands
          })
    },
    async getData() {
      await this.$http.get(`roles/${this.$route.params.id}`)
          .then(res => {
            this.name = res.data.data.name
            this.brand = res.data.data.brand
            this.s4s_staff = res.data.data.s4s_staff === 1
            this.sales_permission = res.data.data.sales_permission
            this.permissions = Object.keys(res.data.data.permissionsModularity).map((key) => {
              return res.data.data.permissionsModularity[key]
            })
          })
      this.is_loading = false
    },
    changeCheckboxPermission(event, data) {
      const indexModule = data.index
      const key = data.field.key
      const value = event
      if (key === "manage") {
        this.keys.forEach(item => {
          this.permissions[indexModule][item] = value
        })
      } else {
        if ((key === "update" || key === "delete") && value) {
          this.permissions[indexModule].read = true
        }else if(key === "read" && !value){
          this.permissions[indexModule].update = false
          this.permissions[indexModule].delete = false
        }
        let isChangeManage = true
        this.keys.forEach(item => {
          if (this.permissions[indexModule][item] !== value) {
            isChangeManage = !value;
          }
        })
        if (isChangeManage) {
          this.permissions[indexModule].manage = value
        }
      }
    },
    submitForm() {
      this.$http.put(`roles/${this.$route.params.id}`, {
        name: this.name,
        brand: this.brand,
        permissions: this.permissions,
        sales_permission: this.sales_permission,
        s4s_staff: this.s4s_staff
      }).then(response => {
        this.$router.replace({name: 'roles'})
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
      }).catch(error => {
        if (error.data && error.data.errors) {
          this.$refs.refFormObserver.setErrors(error.data.errors)
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
